import { Row, expandBaseWebBorder, expandPadding } from "@gadgetinc/widgets";
import { DocsLightTheme } from "@gadgetinc/widgets/src/DocsTheme";
import { HelpIcon } from "@gadgetinc/widgets/src/icons/HelpIcon";
import { styled, useStyletron } from "baseui";
import { HeadingMedium, HeadingSmall, ParagraphMedium, ParagraphSmall } from "baseui/typography";
import type { ReactNode } from "react";
import React from "react";
import { DocLink } from "../markdown-components/DocLink";

export const SplashHeading = styled("h1", ({ $theme }) => ({
  ...$theme.typography.HeadingLarge,
  lineHeight: "120%",
  [DocsLightTheme.mediaQuery.xsmall]: {
    ...$theme.typography.HeadingXXLarge,
  },
}));

export const SplashContentContainer = styled("div", ({ $theme }) => ({
  maxWidth: "1280px",
  paddingLeft: $theme.sizing.scale600,
  paddingRight: $theme.sizing.scale600,
  paddingTop: $theme.sizing.scale800,
  paddingBottom: $theme.sizing.scale800,
  [DocsLightTheme.mediaQuery.xsmall]: {
    paddingTop: "48px",
    paddingBottom: "48px",
    margin: "0 auto",
  },
  [DocsLightTheme.mediaQuery.medium]: {
    paddingTop: "72px",
    paddingBottom: "72px",
  },
}));

export const SplashCardGrid = styled<{ $maxColumns?: number }, "div">("div", ({ $theme, $maxColumns = 3 }) => ({
  display: "grid",
  gridGap: $theme.sizing.scale600,
  gridTemplateColumns: "1fr",
  [DocsLightTheme.mediaQuery.xsmall]: {
    gridTemplateColumns: `repeat(${Math.min(2, $maxColumns)}, 1fr)`,
  },
  [DocsLightTheme.mediaQuery.medium]: {
    gridTemplateColumns: `repeat(${Math.min(3, $maxColumns)}, 1fr)`,
  },
}));

export const SplashCalloutLink = (props: { children: ReactNode }) => {
  const [css, $theme] = useStyletron();

  return (
    <Row
      className={css({
        display: "inline-flex",
        ...expandBaseWebBorder($theme.borders.border100),
        paddingTop: $theme.sizing.scale300,
        paddingBottom: $theme.sizing.scale300,
        paddingLeft: $theme.sizing.scale500,
        paddingRight: $theme.sizing.scale500,
        backgroundColor: $theme.colors.backgroundSecondary,
      })}
      $gap={$theme.sizing.scale300}
    >
      <HelpIcon $size={20} />
      <ParagraphSmall>{props.children}</ParagraphSmall>
    </Row>
  );
};

export interface SplashCardColor {
  content: string;
  background: string;
  border: string;
  boxShadow: string;
}

export const colors = {
  blue: {
    content: "#2C79A5",
    background: "#EFF8FF",
    border: "#92D7FE",
    boxShadow: "rgba(44, 121, 165, 0.12)",
  },
  purple: {
    content: "#361D77",
    background: "#F5F2FC",
    border: "#CBBEE9",
    boxShadow: "rgba(54, 29, 119, 0.12)",
  },
  yellow: {
    content: "#A57213",
    background: "#FBF2DE",
    border: "#C9B78F",
    boxShadow: "rgba(102, 70, 8, 0.16)",
  },
  electricBlue: {
    content: DocsLightTheme.colors.electricBlue600,
    background: DocsLightTheme.colors.electricBlue50,
    border: "#A7A7CE",
    boxShadow: "rgba(53, 53, 206, 0.16)",
  },
  white: {
    content: DocsLightTheme.colors.contentPrimary,
    background: DocsLightTheme.colors.backgroundPrimary,
    border: DocsLightTheme.borders.border600.borderColor,
    boxShadow: "rgba(0, 0, 0, 0.07)",
  },
  grey: {
    content: "#333333",
    background: "#F6F6F6",
    border: "#CBCBCB",
    boxShadow: "rgba(54, 29, 119, 0.12)",
  },
};

export const SplashCard = (props: {
  title: string;
  body: string;
  href: any;
  copy: string;
  color: SplashCardColor;
  icon?: ReactNode;
  featureImage?: ReactNode;
}) => {
  const [css, $theme] = useStyletron();
  return (
    <DocLink
      href={props.href}
      $style={{
        display: "flex",
        flexDirection: "column",
        color: props.color.content,
        backgroundColor: props.color.background,
        borderColor: props.color.border,
        borderRadius: "8px",
        borderWidth: "1px",
        borderStyle: "solid",
        boxShadow: `0px 4px 16px 0px ${props.color.boxShadow}`,
        minWidth: "200px",
        ...expandPadding($theme.sizing.scale800),
        [DocsLightTheme.breakpoints.medium]: {
          ...expandPadding($theme.sizing.scale900),
        },
        [":hover"]: {
          boxShadow: `0px 6px 16px 0px ${props.color.boxShadow}`,
          filter: "brightness(0.95)",
        },
      }}
    >
      <Row $gap={$theme.sizing.scale600}>
        <div>
          <Row $gap={$theme.sizing.scale400} $style={{ flex: 0 }}>
            {props.icon}
            <HeadingMedium color={props.color.content}>{props.title}</HeadingMedium>
          </Row>
          <ParagraphMedium
            $style={{
              color: props.color.content,
              paddingTop: $theme.sizing.scale600,
              paddingBottom: $theme.sizing.scale600,
              flex: 0,
            }}
          >
            {props.body}
          </ParagraphMedium>
          <div className={css({ flex: "1 1 auto" })} />
          <HeadingSmall color={props.color.content}>{props.copy}</HeadingSmall>
        </div>
        {props.featureImage && (
          <div
            className={css({
              display: "none",
              "@media screen and (min-width: 1100px)": {
                display: "block",
              },
            })}
          >
            {props.featureImage}
          </div>
        )}
      </Row>
    </DocLink>
  );
};
